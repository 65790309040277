import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <Typography
                        variant="h1"
                        sx={{
                            // display: 'flex',
                            // flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                        Get a head start on Marketplaces
                        <Typography
                            component="span"
                            // variant="h1"
                            sx={{
                                fontSize: 'clamp(3rem, 10vw, 4rem)',
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                            }}
                        >
                            &nbsp; and beat your competition!
                        </Typography>
                    </Typography>
                    <Typography
                        textAlign="center"
                        color="text.secondary"
                        sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                    >
                        Get in-depth market analysis and competitive insights about competitor pricing, inventory, listings and keywords using the Competitor Research Tool.
                    </Typography>
                    <Button
                        onClick={() => window.location.href = 'https://rapidapi.com/avishmehta2001/api/real-time-amazon-public-data'}
                        sx={(theme) => ({
                            alignSelf: 'center',
                            backgroundColor: theme.palette.mode === 'light' ? 'primary.main' : 'primary.dark',
                            color: theme.palette.mode === 'light' ? 'white' : 'grey.300',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            boxShadow: theme.palette.mode === 'light'
                                ? '0 4px 6px rgba(0, 0, 0, 0.1)'
                                : '0 4px 6px rgba(0, 0, 0, 0.5)',
                            '&:hover': {
                                backgroundColor: theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
                            },
                        })}
                    >
                        Get Started
                    </Button>
                    {/*<Stack*/}
                    {/*    direction={{ xs: 'column', sm: 'row' }}*/}
                    {/*    alignSelf="center"*/}
                    {/*    spacing={1}*/}
                    {/*    useFlexGap*/}
                    {/*    sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}*/}
                    {/*>*/}
                    {/*    <TextField*/}
                    {/*        id="outlined-basic"*/}
                    {/*        hiddenLabel*/}
                    {/*        size="small"*/}
                    {/*        variant="outlined"*/}
                    {/*        aria-label="Enter your email address"*/}
                    {/*        placeholder="Your email address"*/}
                    {/*        inputProps={{*/}
                    {/*            autoComplete: 'off',*/}
                    {/*            'aria-label': 'Enter your email address',*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <Button variant="contained" color="primary">*/}
                    {/*        Start now*/}
                    {/*    </Button>*/}
                    {/*</Stack>*/}
                    {/*<Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>*/}
                    {/*    By clicking &quot;Start now&quot; you agree to our&nbsp;*/}
                    {/*    <Link href="#" color="primary">*/}
                    {/*        Terms & Conditions*/}
                    {/*    </Link>*/}
                    {/*    .*/}
                    {/*</Typography>*/}
                </Stack>
                {/*<Box*/}
                {/*    id="image"*/}
                {/*    sx={(theme) => ({*/}
                {/*        mt: { xs: 8, sm: 10 },*/}
                {/*        alignSelf: 'center',*/}
                {/*        height: { xs: 200, sm: 700 },*/}
                {/*        width: '100%',*/}
                {/*        backgroundImage:*/}
                {/*            theme.palette.mode === 'light'*/}
                {/*                ? 'url("/static/images/templates/templates-images/hero-light.png")'*/}
                {/*                : 'url("/static/images/templates/templates-images/hero-dark.png")',*/}
                {/*        backgroundSize: 'cover',*/}
                {/*        borderRadius: '10px',*/}
                {/*        outline: '1px solid',*/}
                {/*        outlineColor:*/}
                {/*            theme.palette.mode === 'light'*/}
                {/*                ? alpha('#BFCCD9', 0.5)*/}
                {/*                : alpha('#9CCCFC', 0.1),*/}
                {/*        boxShadow:*/}
                {/*            theme.palette.mode === 'light'*/}
                {/*                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`*/}
                {/*                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,*/}
                {/*    })}*/}
                {/*/>*/}
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        id="image"
                        sx={(theme) => ({
                            mt: { xs: 8, sm: 10 },
                            alignSelf: 'center',
                            height: { xs: 200, sm: 700 },
                            width: '100%',
                            backgroundImage:
                                theme.palette.mode === 'light'
                                    ? 'url("/static/comp.jpeg")'
                                    : 'url("/static/comp.jpeg")',
                            backgroundSize: 'cover',
                            borderRadius: '10px',
                            outline: '1px solid',
                            outlineColor:
                                theme.palette.mode === 'light'
                                    ? alpha('#BFCCD9', 0.5)
                                    : alpha('#9CCCFC', 0.1),
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                    : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        })}
                    >
                    </Box>
                </Container>
            </Container>
        </Box>
    );
}