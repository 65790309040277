import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import {EventAvailable, Inventory, PriceChange} from "@mui/icons-material";
import DiscountIcon from '@mui/icons-material/Discount';
import StarIcon from '@mui/icons-material/Star';

const items = [
    {
        icon: <EventAvailable />,
        title: 'Regions and Marketplaces',
        description: 'Available in all regions of amazon to help you grow your business.'
    },
    {
        icon: <PriceChange />,
        title: 'Track prices',
        description:
            'Track your competitors prices and make informed decisions to stay ahead of the competition.',
    },
    {
        icon: <DiscountIcon />,
        title: 'Track best seller rank and deals',
        description: 'Track your competitors best seller rank and deals to stay ahead of the competition.'
    },
    {
        icon: <StarIcon />,
        title: 'Track ratings, reviews and their sentiment',
        description: 'Track your competitors ratings, reviews and their sentiment to stay ahead of the competition.'
    },
    {
        icon: <Inventory />,
        title: 'Track inventory',
        description: 'Track your competitors inventory and get recommendation alerts to stay ahead of the competition.'
    },
    {
        icon: <SettingsSuggestRoundedIcon />,
        title: 'Customizable to your needs',
        description:
            'Customize our product to fit your requirements, ensuring a seamless experience for your workflow.',
    },
    {
        icon: <SummarizeIcon />,
        title: 'Daily Reports',
        description:
            'Get daily reports on your business performance and make informed decisions to grow your business.',
    },
    {
        icon: <AddAlertIcon />,
        title: 'Alerts and Notifications',
        description:
            'Get alerts and notifications on your phone or email to stay updated on your business performance.',
    },
    {
        icon: <AutoFixHighRoundedIcon />,
        title: 'Automated Processes',
        description:
            'Automate your business processes to save time and focus on growing your business.',
    }
];

export default function Highlights() {
    return (
        <Box
            id="highlights"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: '#06090a',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4">
                        Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Discover the features that make our product unique.
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800',
                                    background: 'transparent',
                                    backgroundColor: 'grey.900',
                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}