import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

const logoStyle = {
    width: '200px',
    height: 'auto',
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="/">Brand Analytics&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: 'center', md: 'left' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: { xs: '100%', sm: '60%' },
                    }}
                >
                    <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                        <Box sx={{ ml: '-15px' }}>
                            <img
                                src={
                                    '/static/logo.png'
                                }
                                style={logoStyle}
                                alt="Brand Analytics"
                            />
                        </Box>
                        <br />
                        <Typography variant="body2" fontWeight={600} gutterBottom>
                            Contact us for any queries or feedback.
                        </Typography>
                        <Button variant="contained" color="primary" sx={{ flexShrink: 0 }} onClick={() => {window.location.href = 'https://tally.so/r/w5qWLE'}}>
                            Contact Us
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Product
                    </Typography>
                    <Link color="text.secondary" href="#">
                        Features
                    </Link>
                    <Link color="text.secondary" href="#">
                        Highlights
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Company
                    </Typography>
                    <Link color="text.secondary" href="#">
                        About us
                    </Link>
                    {/*<Link color="text.secondary" href="#">*/}
                    {/*    Careers*/}
                    {/*</Link>*/}
                    {/*<Link color="text.secondary" href="#">*/}
                    {/*    Press*/}
                    {/*</Link>*/}
                </Box>
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: { xs: 'none', sm: 'flex' },*/}
                {/*        flexDirection: 'column',*/}
                {/*        gap: 1,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Typography variant="body2" fontWeight={600}>*/}
                {/*        Legal*/}
                {/*    </Typography>*/}
                {/*    <Link color="text.secondary" href="#">*/}
                {/*        Terms*/}
                {/*    </Link>*/}
                {/*    <Link color="text.secondary" href="#">*/}
                {/*        Privacy*/}
                {/*    </Link>*/}
                {/*    <Link color="text.secondary" href="#">*/}
                {/*        Contact*/}
                {/*    </Link>*/}
                {/*</Box>*/}
                <br />
                <Box
                    sx={{
                        display: { xs: 'flex', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Contact
                    </Typography>
                    <Typography color="text.secondary">
                        Email: divyanshu@brandanalytics.in
                    </Typography>
                    <Typography color="text.secondary">
                        Phone: +91 6377216865
                    </Typography>
                    <Typography color="text.secondary">
                        Address: Bangalore, India
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: { xs: 4, sm: 8 },
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" href="#">
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" href="#">
                        Terms of Service
                    </Link>
                    <Copyright />
                </div>
                {/*<Stack*/}
                {/*    direction="row"*/}
                {/*    justifyContent="left"*/}
                {/*    spacing={1}*/}
                {/*    useFlexGap*/}
                {/*    sx={{*/}
                {/*        color: 'text.secondary',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <IconButton*/}
                {/*        color="inherit"*/}
                {/*        href="https://github.com/mui"*/}
                {/*        aria-label="GitHub"*/}
                {/*        sx={{ alignSelf: 'center' }}*/}
                {/*    >*/}
                {/*        <FacebookIcon />*/}
                {/*    </IconButton>*/}
                {/*    <IconButton*/}
                {/*        color="inherit"*/}
                {/*        href="https://twitter.com/MaterialUI"*/}
                {/*        aria-label="X"*/}
                {/*        sx={{ alignSelf: 'center' }}*/}
                {/*    >*/}
                {/*        <TwitterIcon />*/}
                {/*    </IconButton>*/}
                {/*    <IconButton*/}
                {/*        color="inherit"*/}
                {/*        href="https://www.linkedin.com/company/mui/"*/}
                {/*        aria-label="LinkedIn"*/}
                {/*        sx={{ alignSelf: 'center' }}*/}
                {/*    >*/}
                {/*        <LinkedInIcon />*/}
                {/*    </IconButton>*/}
                {/*</Stack>*/}
            </Box>
        </Container>
    );
}